import React from 'react'
import { LoadingBtn } from '../components/Button'
import Select from '../components/formControls/Select'
import useForm from '../hooks/useForm'
import { TextField, Switch } from '../components/formControls'
import * as yup from 'yup'
import { useMixpanel } from '../hooks/useMixpanel'
import { eventTypes } from '../services/constants'
import { FormControlLabel } from '@mui/material'
import { automatedServices } from '../services/helper'

const AccountForm = ({ account, onSubmit }) => {
	const mixpanel = useMixpanel()
	const schema = yup.object().shape({
		allowedLoginsWithoutMFA: yup
			.number()
			.min(1, 'Must be greater than 1'),
	})

	const form = useForm({
		schema,
		values: account || {
			mfaPreference: 'Disabled',
			allowedLoginsWithoutMFA: 3,
			asoSettings: {},
		},
		resetOptions: {
			keepDirtyValues: true,
		},
	})

	const {
		handleSubmit,
		formState: { errors, isSubmitting },
		control,
		getValues,
		setValue,
		watch,
	} = form

	const submitForm = async () => {
		const data = getValues()
		await onSubmit(data)
	}

	const mfaPreference = watch('mfaPreference')
	const asoSettings = watch('asoSettings')

	const handleSwitchChange = (event, trackingId) => {
		const { checked } = event.target
		setValue('asoSettings', { ...asoSettings, enabled: checked })
		mixpanel.trackEvent(trackingId, {
			preference: checked,
		})
	}

	const handleSelectChange = (event, trackingId) => {
		const { value } = event.target
		mixpanel.trackEvent(trackingId, {
			preference: value,
		})
	}

	const handleServiceToggle = (event, service) => {
		const { checked } = event.target
		setValue('asoSettings', {
			...asoSettings,
			[service.settingId]: checked,
		})
		mixpanel.trackEvent(
			eventTypes.ACCOUNT_PROFILE_ASO_SETTINGS_TOGGLED,
			{
				[service.settingId]: checked,
			}
		)
	}

	return (
		<form onSubmit={handleSubmit(submitForm)} noValidate>
			<div>
				<div className="mb-3">
					<Switch
						name="loanMilestoneNotificationsEnabled"
						label="Send Loan Milestone Notifications"
						control={control}
						errors={errors}
						onChange={(e) =>
							handleSwitchChange(
								e,
								eventTypes.ACCOUNT_PROFILE_MILESTONE_CONFIGURATION_CHANGED
							)
						}
					/>
				</div>
				<div className="w-1/4">
					<Select
						name="mfaPreference"
						className="w-full"
						sx={{ mb: 2 }}
						label="MFA Preference"
						menuItems={[
							{ label: 'Disabled', value: 'Disabled' },
							{ label: 'Optional', value: 'Optional' },
							{ label: 'Required', value: 'Required' },
						]}
						control={control}
						errors={errors}
						onChange={(e) =>
							handleSelectChange(
								e,
								eventTypes.ACCOUNT_PROFILE_MFA_PREFERENCE_CHANGED
							)
						}
					/>
				</div>
				{mfaPreference === 'Required' && (
					<div className="mb-2">
						<TextField
							name="allowedLoginsWithoutMFA"
							label="Allowed logins for borrowers before enforcing MFA"
							className="w-full"
							control={control}
							errors={errors}
						/>
					</div>
				)}
			</div>
			<div className="mt-5">
				<FormControlLabel
					label={`Automated Service Orders is ${asoSettings?.enabled ? 'on' : 'off'}`}
					control={
						<Switch
							id="asoSettingsToggle"
							inputProps={{ 'aria-label': 'controlled' }}
							checked={asoSettings.enabled}
							onChange={(e) =>
								handleSwitchChange(
									e,
									eventTypes.ACCOUNT_PROFILE_ASO_SETTINGS_TOGGLED
								)
							}
						/>
					}
				/>
				<div className="ml-5 flex pt-6">
					{asoSettings?.enabled &&
						automatedServices.map((service) => (
							<div className="w-1/4">
								<div className="text-lg">{service.name}</div>
								{service.items.map((item) => (
									<div>
										<FormControlLabel
											label={`${item.name} is ${asoSettings[item.settingId] ? 'on' : 'off'}`}
											control={
												<Switch
													id={`accountProfileASOSettings${service.name.replace(/ /g, '_')}${item.settingId}`}
													checked={asoSettings[item.settingId]}
													onChange={(e) =>
														handleServiceToggle(e, item)
													}
													inputProps={{
														'aria-label': 'controlled',
													}}
												/>
											}
										/>
									</div>
								))}
							</div>
						))}
				</div>
			</div>
			<div className="mt-10">
				<LoadingBtn
					disabled={isSubmitting}
					loading={isSubmitting}
					type="submit"
					text="Save"
				/>
			</div>
		</form>
	)
}

export default AccountForm
